<template>
    <div class="clear-page">
        <router-view/>
    </div>
</template>

<script>
  export default {
    name: 'ClearTemplate',
    data(){
      return {

      }
    },
    methods: {

    },
  }
</script>
